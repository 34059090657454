import React, {useMemo} from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {Button} from '../core/Button/Button.styles';
import {Hidden} from 'react-awesome-styled-grid';
import {
  Caption,
  Heading02,
  Heading03,
  Heading05,
  Paragraph03,
} from '../core/Typography/Typography.styles';
import {
  AuthorAvatar,
  AuthorInfoContainer,
  AuthorName,
  SmallCardContainer,
  ButtonSection,
  TitleWrapper,
  Category,
  CardCover,
  BottomSection,
} from './LocalStyles';

const PostCard = ({post, large}) => {
  const locale = useMemo(() => {
    if (post && post.locale) {
      return post.locale.locale.replace('_', '-');
    }
    return 'en-US';
  }, [post]);
  const postDate = useMemo(() => {
    if (post) {
      return new Date(post.dateGmt).toLocaleDateString(locale, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }
    return new Date();
  }, [post]);

  const uri = useMemo(() => {
    if (post && post.uri) {
      return post.uri;
    }
    return 'https://www.dacast.com/';
  }, [post]);

  const title = useMemo(() => {
    if (post && post.title) {
      return post.title;
    }
    return 'Title';
  }, [post]);

  const readTime = useMemo(() => {
    if (post && post.content) {
      return Math.ceil(post.content.split(' ').length / 400);
    }
    return '';
  }, [post]);

  const authorName = useMemo(() => {
    if (post && post.author.node) {
      return post.author.node.name;
    }
    return 'Leslie Alexander';
  }, [post]);

  const authorPic = useMemo(() => {
    if (post && post.author.node) {
      return post.author.node.avatar.url;
    }
    return 'https://www.dacast.com/wp-content/uploads/2012/02/admin_avatar.jpeg';
  }, [post]);

  const backdrop = useMemo(() => {
    if (post && post.featuredImage && post.featuredImage.node) {
      return post.featuredImage.node.sourceUrl;
    }
    return 'https://www.dacast.com/wp-content/uploads/2020/02/live-broadcasting-software.jpg';
  }, [post]);

  const category = useMemo(() => {
    if (post && post.categories.nodes[0]) {
      return post.categories.nodes[0].name;
    }
    return '';
  }, [post]);

  return (
    <Link to={`${uri}`}>
      {category !== 'Dacast News' ? (
        <SmallCardContainer type={category}>
          <CardCover backdrop={backdrop}>
            <Category badge category={category}>
              <Caption fontWeight="bold">{category}</Caption>
            </Category>
          </CardCover>
          <TitleWrapper large={large}>
            {!large ? (
              <Heading05 fontWeight="bold" palette="shades" scale="100">
                {title}
              </Heading05>
            ) : (
              <Heading03 fontWeight="bold" palette="shades" scale="100">
                {title}
              </Heading03>
            )}
          </TitleWrapper>
          {category === 'Dacast News' ? (
            <>
              <Hidden xs>
                <ButtonSection>
                  <Button large primary>
                    Learn More
                  </Button>
                </ButtonSection>
              </Hidden>
              <Hidden sm md lg xl>
                <ButtonSection>
                  <Button primary large>
                    Learn More
                  </Button>
                </ButtonSection>
              </Hidden>
            </>
          ) : (
            <BottomSection>
              <AuthorInfoContainer>
                <AuthorAvatar src={authorPic} />
                <AuthorName>
                  <Paragraph03 fontWeight="medium" palette="shades" scale="100">
                    {authorName}
                  </Paragraph03>
                  <Paragraph03
                    fontWeight="regular"
                    palette="neutral"
                    scale="600"
                  >
                    {post ? postDate : 'Jan 12, 2021'}
                  </Paragraph03>
                </AuthorName>
              </AuthorInfoContainer>
              {readTime} min
            </BottomSection>
          )}
        </SmallCardContainer>
      ) : (
        <SmallCardContainer type={category} backdrop={backdrop}>
          <Category badge category={category}>
            <Caption fontWeight="bold">News</Caption>
          </Category>
          <TitleWrapper type={category}>
            <Heading02
              style={{backdropFilter: 'blur(100px)'}}
              small
              fontWeight="black"
              palette="shades"
              scale="0"
            >
              {title}
            </Heading02>
          </TitleWrapper>
          <Hidden xs>
            <ButtonSection type={category}>
              <Button tertiary large>
                Learn More
              </Button>
            </ButtonSection>
          </Hidden>
          <Hidden sm md lg xl>
            <ButtonSection>
              <Button primary large minWidth="100%">
                Learn More
              </Button>
            </ButtonSection>
          </Hidden>
        </SmallCardContainer>
      )}
    </Link>
  );
};

PostCard.propTypes = {
  post: PropTypes.object,
  large: PropTypes.bool,
};

export default PostCard;
