import {useEffect} from 'react';
import {withPrefix} from 'gatsby';

const CornerstoneScript = 'static/cornerstones.js?v=0.04';
const useScript = async (
  resourceUrl = withPrefix(CornerstoneScript),
  id = null
) => {
  useEffect(() => {
    let script = null;
    script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = resourceUrl;
    script.id = id;
    document.body.appendChild(script);

    return () => {
      script && document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

export default useScript;
