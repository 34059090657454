import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {
  Caption,
  Heading01,
  Heading03,
  Heading04,
  Heading06,
} from '../core/Typography/Typography.styles';
import {
  CategoriesWrapper,
  Category,
  Input,
  LargeCardContainer,
  SearchIcon,
  SearchWrapper,
  TitleWrapper,
} from './LocalStyles';

const SearchCard = ({categories, type, backdrop}) => {
  const [, setSearchTerm] = useState('');

  const searchForm = useRef(null);

  const handleSubmit = () => {
    searchForm.current.submit();
  };

  const title = {
    blog: 'The Modern Way of Streaming Your Content',
    faq: 'Search Knowledge Base by Keyword',
  };

  const newUrl = uri => {
    if (type === 'blog') {
      return uri.includes('category') ? uri.split('/category')[1] : uri;
    } else {
      return uri;
    }
  };

  const getUrl = () => {
    let actionUrl = '';
    if (type === 'blog') {
      actionUrl = 'https://www.dacast.com/blog/';
    } else {
      actionUrl = 'https://www.dacast.com/';
    }
    return actionUrl;
  };

  return (
    <LargeCardContainer backdrop={backdrop}>
      <TitleWrapper type="search">
        <Heading04 small fontWeight="black" palette="shades" scale="0">
          {title[type]}
        </Heading04>
        <Heading03 small fontWeight="black" palette="shades" scale="0">
          {title[type]}
        </Heading03>
        <Heading01 fontWeight="bold" palette="shades" scale="0">
          {title[type]}
        </Heading01>
      </TitleWrapper>
      {type === 'blog' && (
        <Heading06 small fontWeight="medium" palette="shades" scale="0">
          Get to know your streaming tools and reach your goals
        </Heading06>
      )}
      <SearchWrapper ref={searchForm} action={getUrl()}>
        <Input
          placeholder="Search"
          name="s"
          onChange={e => setSearchTerm(e.target.value)}
          autoFocus
        />
        {type !== 'blog' && (
          <Input type="hidden" name="post_type" value="knowledgebase" />
        )}
        <SearchIcon icon="search" onClick={handleSubmit} />
      </SearchWrapper>
      <CategoriesWrapper>
        {categories.map(({name, uri}, index) => (
          <Link to={uri} key={index}>
            <Category category={name}>
              <Caption fontWeight="bold">
                {name === 'Dacast News' ? 'News' : name}
              </Caption>
            </Category>
          </Link>
        ))}
      </CategoriesWrapper>
    </LargeCardContainer>
  );
};

export default SearchCard;

SearchCard.propTypes = {
  categories: PropTypes.array,
  type: PropTypes.string,
  backdrop: PropTypes.string,
};
