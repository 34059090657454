import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {Paragraph01, Subheading} from '../core/Typography/Typography.styles';
import {
  DesktopPagination,
  MobilePagination,
  PageButton,
  PaginationContainer,
} from './LocalStyles';

const Pagination = ({currentPage, pageCount, category}) => {
  const pagesArray = Array.from(Array(pageCount).keys());
  const [pageSiblings, setPageSiblings] = useState(pagesArray.slice(0, 3));

  useEffect(() => {
    if (pageCount > 5) {
      if (currentPage > pageSiblings[pageSiblings.length - 1]) {
        if (pageCount - currentPage < 2) {
          setPageSiblings(pagesArray.slice(pageCount - 2, pageCount));
        } else if (currentPage <= 4) {
          setPageSiblings(pagesArray.slice(0, 5));
        } else {
          setPageSiblings(pagesArray.slice(currentPage - 1, currentPage + 1));
        }
      }
      if (currentPage < pageSiblings[0]) {
        setPageSiblings(pagesArray.slice(currentPage - 2, currentPage - 1));
      }
    }
  }, [currentPage, pageCount, pageSiblings, pagesArray]);

  return (
    <PaginationContainer>
      <MobilePagination>
        {currentPage === 1 ? (
          <Link title="Go to next page" to={`/${category}/${currentPage + 1}/`}>
            <PageButton>
              <Paragraph01 fontWeight="medium">Next </Paragraph01>
              <span className="material-icons">navigate_next</span>
            </PageButton>
          </Link>
        ) : (
          <>
            {currentPage > 2 && (
              <Link title="Go to first page" to={`/${category}/`}>
                <PageButton>
                  <span className="material-icons">first_page</span>
                </PageButton>
              </Link>
            )}
            <Link
              title="Go to previous page"
              to={`/${category}/${currentPage <= 2 ? '' : currentPage - 1}${
                currentPage > 1 ? '' : '/'
              }`}
            >
              <PageButton>
                <span className="material-icons">navigate_before</span>
              </PageButton>
            </Link>
            {currentPage < pageCount ? (
              <>
                <Paragraph01 fontWeight="medium">
                  Page {currentPage}
                </Paragraph01>
                <Link
                  title="Go to next page"
                  to={`/${category}/${currentPage + 1}/`}
                >
                  <PageButton>
                    <span className="material-icons">navigate_next</span>
                  </PageButton>
                </Link>
                <Link
                  title="Go to last page"
                  to={`/${category}/${currentPage + 1}/`}
                >
                  <PageButton>
                    <span className="material-icons">last_page</span>
                  </PageButton>
                </Link>
              </>
            ) : (
              <>
                <Paragraph01 fontWeight="medium">Last Page</Paragraph01>
              </>
            )}
          </>
        )}
      </MobilePagination>
      <DesktopPagination>
        {currentPage > 1 && (
          <Link
            title="Go back"
            to={`/${category}/${currentPage > 2 ? currentPage - 1 : ''}${
              currentPage > 1 ? '' : '/'
            }`}
          >
            <PageButton>
              <span className="material-icons">navigate_before</span>
            </PageButton>
          </Link>
        )}
        {currentPage >= 5 && (
          <>
            <Link title="Go to next page" to={`/${category}/`}>
              <PageButton className="pageNumber">
                <Subheading fontWeight="regular" palette="neutral" scale={'0'}>
                  1
                </Subheading>
              </PageButton>
            </Link>
            <Link title="Go to next page" to={`/${category}/2`}>
              <PageButton className="pageNumber">
                <Subheading fontWeight="regular" palette="neutral" scale={'0'}>
                  2
                </Subheading>
              </PageButton>
            </Link>
            {currentPage > 4 && (
              <PageButton className="pageNumber">
                <Subheading fontWeight="regular" palette="neutral" scale={'0'}>
                  ...
                </Subheading>
              </PageButton>
            )}
          </>
        )}
        {pageSiblings.map(number => (
          <Link
            title="Go to next page"
            to={`/${category}/${number === 0 ? '' : number + 1}${
              number === 0 ? '' : '/'
            }`}
            key={number}
          >
            <PageButton
              active={currentPage === number + 1}
              className="pageNumber"
            >
              <Subheading
                fontWeight="regular"
                palette="neutral"
                scale={currentPage - 1 === number ? '100' : '0'}
              >
                {number + 1}
              </Subheading>
            </PageButton>
          </Link>
        ))}
        {currentPage < pageCount && (
          <Link title="Go to next page" to={`/${category}/${currentPage + 1}/`}>
            <PageButton>
              <span className="material-icons">navigate_next</span>
            </PageButton>
          </Link>
        )}
      </DesktopPagination>
    </PaginationContainer>
  );
};

export default Pagination;

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  category: PropTypes.string,
};
