import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-awesome-styled-grid';
import SearchCard from './SearchCard';
import PostCard from './PostCard';
import SubsCard from './SubsCard';
import {Separator0, Separator1, Separator2} from './LocalStyles';
import {graphql, withPrefix} from 'gatsby';
import Pagination from './Pagination';
import SEO from '../seo';
import {Helmet} from 'react-helmet';
import useScript from '../../hooks/use-script';
import Layout from '../Layout/index.js';

const Blog = ({data, pageContext}) => {
  useScript(withPrefix('static/recaptcha.js?v=0.06'));
  useScript(withPrefix('static/main-blogLanding.js?v=0.04'));
  useScript(withPrefix('static/customerIO.js?v=0.01'));
  const localeMapping = {
    de: {
      locale: 'de_DE',
      posts: data.postsDE,
      count: pageContext.pageCountDE,
    },
    en: {
      locale: 'en_US',
      posts: data.postsEN,
      count: pageContext.pageCountEN,
    },
    es: {
      locale: 'es_ES',
      posts: data.postsES,
      count: pageContext.pageCountES,
    },
    fr: {
      locale: 'fr_FR',
      posts: data.postsFR,
      count: pageContext.pageCountFR,
    },
    it: {
      locale: 'it_IT',
      posts: data.postsIT,
      count: pageContext.pageCountIT,
    },
    pt: {
      locale: 'pt_BR',
      posts: data.postsPT,
      count: pageContext.pageCountPT,
    },
  };

  const currentLocaleContent = localeMapping[pageContext.language];

  const posts = useMemo(() => {
    if (currentLocaleContent.posts.edges.length > 0) {
      return currentLocaleContent.posts.edges.map(item => item.node);
    }
    return [];
  }, [currentLocaleContent]);

  const count = useMemo(() => {
    if (currentLocaleContent.count) {
      return currentLocaleContent.count;
    }
    return 1;
  }, [currentLocaleContent]);

  const categories = useMemo(() => {
    if (data.categories.edges.length > 0) {
      return data.categories.edges.map(item => item.node);
    }
    return [];
  }, [data]);

  const locales = useMemo(() => {
    if (data.locales.edges.length > 0) {
      return data.locales.edges.map(item => item.node);
    }
    return [];
  }, [data]);

  const seo = useMemo(() => {
    let canonical = '';
    if (data.blogPageData.blogPageData && data.blogPageData.edges.length > 0) {
      return data.blogPageData.edges.map(item => item.node)[0].seo;
    }
    if (pageContext.category == 'blog') {
      canonical = 'https://www.dacast.com/category/blog';
    } else {
      canonical = `https://www.dacast.com/${
        pageContext.language === 'en' ? '' : `${pageContext.language}/`
      }${pageContext.category}/`;
    }
    return {
      canonical,
      title: 'All you Need to Know about Live Video Streaming is Here',
      metaDesc:
        'Dacast has been writing specific articles on the online video industry for more than 10 years. Discover the latest trends and the best practices here!',
      opengraphType: 'article',
      opengraphSiteName: 'Dacast',
      opengraphPublisher: 'https://www.facebook.com/DacastStreaming/',
      opengraphPublishedTime: '',
      opengraphModifiedTime: '2020-02-12T00:12:26+00:00',
      schema: {
        articleType: [],
        pageType: ['WebPage', 'CollectionPage'],
        raw:
          '{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.dacast.com/#website","url":"https://www.dacast.com/","name":"Dacast","description":"Video Hosting &amp; Live Streaming Solutions","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://www.dacast.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.dacast.com/support/knowledgebase/#webpage","url":"https://www.dacast.com/support/knowledgebase/","name":"Best Knowledge Base for Live Streaming and Video Hosting","isPartOf":{"@id":"https://www.dacast.com/#website"},"datePublished":"2019-01-10T22:06:28+00:00","dateModified":"2020-06-15T23:08:41+00:00","description":"Looking for the best tips on how to live stream and host your online video ? Check out Dacast video and written tutorials and enjoy 30 days of free streaming!","breadcrumb":{"@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.dacast.com/support/knowledgebase/"]}]},{"@type":"BreadcrumbList","@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"https://www.dacast.com/"},{"@type":"ListItem","position":2,"name":"24/7 Support","item":"https://www.dacast.com/support/"},{"@type":"ListItem","position":3,"name":"Knowledge Base"}]}]}',
      },
    };
  }, [data]);

  const translated = useMemo(() => {
    if (data.blogPageData && data.blogPageData.edges.length > 0) {
      return data.blogPageData.edges.map(item => item.node).translated;
    }
    return [];
  }, [data]);

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href={`${seo.canonical}${
            pageContext.currentPage > 1 ? pageContext.currentPage : ''
          }`}
        />
        <script
          type="text/javascript"
          src="//js.hsforms.net/forms/v2.js"
        ></script>
      </Helmet>
      <SEO
        seo={seo}
        lang={pageContext.language}
        locales={locales}
        translated={translated}
        databaseId={28856}
      />
      <Separator0 />
      <Container>
        <Row>
          <Col xs={12} lg={8}>
            <SearchCard type="blog" categories={categories} />
          </Col>
          <Separator1 />
          <Col sm={4} lg={4}>
            {posts[0] && <PostCard post={posts[0]} />}
          </Col>
          <Separator2 />
          <Col sm={4} lg={4}>
            {posts[1] && <PostCard post={posts[1]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[2] && <PostCard post={posts[2]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[3] && <PostCard post={posts[3]} />}
          </Col>
          <Col sm={12} lg={12}>
            <SubsCard />
          </Col>
          <Col sm={4} lg={4}>
            {posts[4] && <PostCard post={posts[4]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[5] && <PostCard post={posts[5]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[6] && <PostCard post={posts[6]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[7] && <PostCard post={posts[7]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[8] && <PostCard post={posts[8]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[9] && <PostCard post={posts[9]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[10] && <PostCard post={posts[10]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[11] && <PostCard post={posts[11]} />}
          </Col>
          <Col sm={12} lg={4}>
            {posts[12] && <PostCard post={posts[12]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[13] && <PostCard post={posts[13]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[14] && <PostCard post={posts[14]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[15] && <PostCard post={posts[15]} />}
          </Col>
          <Col sm={4} lg={4}>
            {posts[16] && <PostCard post={posts[16]} />}
          </Col>
        </Row>
      </Container>
      <Pagination
        pageCount={count}
        currentPage={pageContext.currentPage}
        category={pageContext.category}
      />
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!, $language: String!, $category: String!) {
    blogPageData: allWpPost(filter: {id: {eq: "28856"}}) {
      edges {
        node {
          id
          content
          seo {
            canonical
            title
            metaDesc
            opengraphType
            opengraphSiteName
            opengraphPublisher
            opengraphPublishedTime
            opengraphModifiedTime
            schema {
              articleType
              pageType
              raw
            }
          }
          translated {
            seo {
              canonical
              title
              metaDesc
              opengraphType
              opengraphSiteName
              opengraphPublisher
              opengraphPublishedTime
              opengraphModifiedTime
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
      }
    }
    categories: allWpCategory {
      edges {
        node {
          name
          uri
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    postsEN: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {
        categories: {nodes: {elemMatch: {slug: {eq: $category}}}}
        locale: {locale: {eq: "en_US"}}
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    postsES: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {locale: {locale: {eq: "es_ES"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    postsDE: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {locale: {locale: {eq: "de_DE"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    postsFR: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {locale: {locale: {eq: "fr_FR"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    postsIT: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {locale: {locale: {eq: "it_IT"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    postsPT: allWpPost(
      sort: {fields: [dateGmt], order: DESC}
      filter: {locale: {locale: {eq: "pt_BR"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
  }
`;

export default Blog;

Blog.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
